import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { Story as IStory } from "../types/story"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import { PostHeader } from "../layout/PostHeader"
import { DatoCmsContent } from "../layout/DatoCmsContent"
import { StoryThumbnailSet } from "../layout/StoryThumbnailSet"
import { Favicon } from "../components/Favicon"
import { PillSet } from "../layout/DatoCmsContent/sections/PillSet"
import { PillSetProps } from "../layout/DatoCmsContent/sections/PillSet/PillSet"
import { PageLink } from "../utils/pageLink"

const Story: React.FC<StoryData> = ({ data: { story, overview } }) => {
  const contactPillSet: PillSetProps = useMemo(
    () => ({
      __typename: "DatoCmsPillSet",
      id: "contact-details",
      greenish: false,
      title: overview.contactDetailsLabel,
      pills: [
        {
          id: "contact",
          __typename: "DatoCmsAction",
          label: overview.actionLabel,
          style: "regular",
          link: [
            {
              __typename: "DatoCmsPageLink",
              page: overview.contactPageLink,
            },
          ],
        },
      ],
    }),
    [
      overview.contactDetailsLabel,
      overview.actionLabel,
      overview.contactPageLink,
    ]
  )
  return (
    <article>
      <PostHeader
        image={story.headerImage}
        imageStyle={"plain"}
        showSocialMediaLinks={false}
        {...story}
      />
      {story.content.map((content, i) => (
        <DatoCmsContent
          //@ts-ignore
          key={content?.id}
          verticalMargins={"article"}
          before={i > 0 ? story.content[i - 1] : undefined}
          after={story.content[i + 1] || contactPillSet}
          {...content}
        />
      ))}
      <DatoCmsContent
        verticalMargins={"article"}
        before={story.content[story.content.length - 1]}
        {...contactPillSet}
      />
      {story.relatedStories && story.relatedStories.length > 0 && (
        <StoryThumbnailSet
          title={story.relatedStoriesLabel}
          stories={story.relatedStories}
        />
      )}
      <HelmetDatoCms seo={story.seoMetaTags} />
      <Favicon />
    </article>
  )
}

export default Story

type StoryData = {
  data: {
    story: IStory & { seoMetaTags: any }
    overview: {
      contactDetailsLabel: string
      actionLabel: string
      contactPageLink: PageLink
    }
  }
}

export const query = graphql`
  query($id: String) {
    overview: datoCmsEntrepreneurialStoriesOverview {
      contactDetailsLabel
      actionLabel
      contactPageLink {
        ...FormPageLink
      }
    }
    story: datoCmsEntrepreneurialStory(id: { eq: $id }) {
      ...Story

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
