import React from "react"
import { StoryThumbnail as IStoryThumbnail } from "../../types/story"
import * as S from "./styled"
import { StoryThumbnail } from "../../components/StoryThumbnail"

export type StoryThumbnailSetProps = {
  stories: IStoryThumbnail[]
  title?: string
  greenish?: boolean
}

export const StoryThumbnailSet: React.FC<StoryThumbnailSetProps> = props => (
  <S.Container hasTitle={!!props.title} greenish={props.greenish}>
    {props.title && (
      <S.Title row={1} tabletRow={1} column={"4 / 12"} tabletCol={"3 / 7"}>
        {props.title}
      </S.Title>
    )}

    {props.stories.map((s, i) => (
      <S.MessageCell key={s.id} index={i} hasTitle={!!props.title}>
        <StoryThumbnail animateInView {...s} />
      </S.MessageCell>
    ))}
  </S.Container>
)
