import styled, { css } from "styled-components"
import { GridCell, GridLayout } from "../../components/GridLayout"
import device from "../../components/Theme/devices"
import { SectionTitle } from "../../components/Texts"

export const Container = styled(GridLayout)<{ hasTitle: boolean }>`
  grid-row-gap: 70px;

  > :first-child {
    margin-top: -30px;
  }

  :last-child {
    padding-bottom: 180px;
  }

  @media (${device.tablet}) {
    ${props =>
      !props.hasTitle &&
      css`
        padding-top: 0;
      `}
    > :nth-child(-n + 2) {
      margin-top: -70px;
    }
  }
`

export const Title = styled(GridCell).attrs({
  as: SectionTitle,
  centered: true,
})`
  margin-bottom: 70px;
  && {
    margin-top: 0;
  }
`

const rowForIndex = (index: number, hasTitle: boolean) => {
  const row = index + 1 + (hasTitle ? 1 : 0)
  return `${row} / ${row + 2} `
}

export const MessageCell = styled(GridCell).attrs(
  ({ index, hasTitle }: { index: number; hasTitle: boolean }) => ({
    row: "",
    tabletRow: "",
    column: index % 2 === 0 ? "3 / 7" : "9 / 13",
    tabletCol: index % 2 === 0 ? "2 / 5" : "5 / 8",
    leftMargin: "phone-only",
    rightMargin: "phone-only",
  })
)<{ index: number; hasTitle: boolean }>`
  ${props => css`
    @media (${device.tablet}) {
      :nth-child(${props.index + 1}) {
        grid-row: ${rowForIndex(props.index, props.hasTitle)};
      }
    }
  `}
`
